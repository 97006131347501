import { useLocation, useMatches, useSearchParams, useSubmit } from "@remix-run/react";
import { useRootData } from "~/utils/data/useRootData";
import AnalyticsHelper from "~/utils/helpers/AnalyticsHelper";
import clsx from "~/utils/shared/ClassesUtils";

export default function DarkModeToggle({ className, width }: { className?: string; width?: "w-full" | "w-auto" }) {
  const { userSession } = useRootData();
  let location = useLocation();
  const [searchParams] = useSearchParams();
  const submit = useSubmit();
  const rootData = useRootData();
  const matches = useMatches();

  const toggle = async () => {
    const form = new FormData();
    form.set("action", "toggleLightOrDarkMode");
    form.set("redirect", location.pathname + "?" + searchParams.toString());
    submit(form, { method: "post", action: "/", replace: false });

    const routeMatch = matches.find((m) => m.pathname == location.pathname);
    AnalyticsHelper.addEvent({
      url: location.pathname,
      route: routeMatch?.id,
      rootData,
      action: "toggleLightOrDarkMode",
      category: "user",
      label: "lightOrDarkMode",
      value: isDarkMode ? "light" : "dark",
    });
  };
  const isDarkMode = userSession?.lightOrDarkMode === "dark";

  return (
    <button
      type="button"
      onClick={toggle}
      className={clsx(
        className,
        "inline-flex items-center justify-center rounded-md px-2 py-1 font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500 dark:hover:bg-gray-600 dark:hover:text-gray-300",
        width
      )}
    >
      {isDarkMode ? (
        <svg className="h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z"
          />
        </svg>
      ) : (
        <svg className="h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
          />
        </svg>
      )}
      {/* <span className="text-base font-medium">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
          />
        </svg>
      </span>
      <div className="focus:ring-theme-500 relative rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2">
        <div className="h-3.5 w-6 rounded-full bg-gray-500 shadow-md outline-none transition"></div>
        <div
          className={`absolute left-1 top-1 inline-flex h-1.5 w-1.5 transform items-center justify-center rounded-full bg-white shadow-sm transition-all duration-200 ease-in-out
                  ${isDarkMode ? "translate-x-3" : ""}`}
        ></div>
      </div>
      <span className="text-base font-medium">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
          />
        </svg>
      </span> */}
    </button>
  );
}
